import React from "react";
import { GamePageData } from "./RecipeRescuePageData";

export const superstarSlotsPageData: GamePageData = {
    metaPageTitle: "Superstar Slots Vegas Slot Machine Game - iDream Interactive",
    metaKeywords: "superstar slots, vegas slot machine game",
    metaDescription: "Our Vegas style slot machines are one of a kind! Learn more about our second slot machine game released in 2012 which was available on Facebook.",
    pageTitle: "Superstar Slots",
    descriptionTitle: "Our Vegas style slot machines are one of a kind!",
    description: <>
        <p>Superstar Slots was the newest and greatest slot machine experience on Facebook. </p>
        <p>Superstar Slots was our second slot machine style game released in 2012.  Although not as successful as Slot Universe, it allowed us to push the boundaries of our artistic creativity.  To this day, we continue to use artwork from this title in our promotional slide decks.</p>
    </>,
    links: [
    ]
}
